import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function FavoritesButton({ sx, ...other }) {
  const settings = useSettingsContext();

  return (
    <Box component={m.div}>
      <Tooltip enterDelay={600} title={'Favorites'}>
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          aria-label="settings"
          onClick={settings.onToggle}
          sx={{
            width: 40,
            height: 40,
          }}
          {...other}
        >
          <Iconify icon="solar:heart-bold" color="primary" width={24} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

FavoritesButton.propTypes = {
  sx: PropTypes.object,
};
