import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  featuredCareers: [],
  featuredCareersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  findCareersResults: [],
  findCareersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    // GET FEATURED CAREERS
    getFeaturedCareersStart(state) {
      state.featuredCareersStatus.loading = true;
      state.featuredCareersStatus.empty = false;
      state.featuredCareersStatus.error = null;
    },
    getFeaturedCareersFailure(state, action) {
      state.featuredCareersStatus.loading = false;
      state.featuredCareersStatus.empty = false;
      state.featuredCareersStatus.error = action.payload;
    },
    getFeaturedCareersSuccess(state, action) {
      const featuredCareers = action.payload;

      state.featuredCareers = featuredCareers;

      state.featuredCareersStatus.loading = false;
      state.featuredCareersStatus.empty = !featuredCareers.length;
      state.featuredCareersStatus.error = null;
    },
    // FIND CAREERS
    findCareersStart(state) {
      state.findCareersStatus.loading = true;
      state.findCareersStatus.empty = false;
      state.findCareersStatus.error = null;
    },
    findCareersFailure(state, action) {
      state.findCareersStatus.loading = false;
      state.findCareersStatus.empty = false;
      state.findCareersStatus.error = action.payload;
    },
    findCareersSuccess(state, action) {
      const findCareersResults = action.payload;

      state.findCareersResults = findCareersResults;

      state.findCareersStatus.loading = false;
      state.findCareersStatus.empty = !findCareersResults.length;
      state.findCareersStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFeaturedCareers() {
  return async (dispatch) => {
    dispatch(slice.actions.getFeaturedCareersStart());
    try {
      const response = await axios.get(API_ENDPOINTS.career.getFeaturedCareers);
      dispatch(slice.actions.getFeaturedCareersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getFeaturedCareersFailure(error));
    }
  };
}

export function findCareers(filters, keyword) {
  return async (dispatch) => {
    dispatch(slice.actions.findCareersStart());
    try {
      const body = {
        ...(keyword && { Keyword: keyword.trim() }),
        ...filters.values,
      };
      const response = await axios.post(API_ENDPOINTS.career.findCareers, body);
      dispatch(slice.actions.findCareersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.findCareersFailure(error));
    }
  };
}
