import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
// components
import Logo from 'src/components/logo';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
//
import Footer from './footer';
import { Header, LoginButton, PageContainer } from '../_common';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';
//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();

  const mdUp = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <>
      <Header
        leftAdornment={
          <>
            {/* Mobile nav buttons */}
            {!mdUp && <NavMobile data={navConfig} sx={{ mx: 0 }} />}

            {mdUp && <Logo navTo="/" sx={{ mx: 0 }} />}
          </>
        }
        centerAdornment={<>{!mdUp && <Logo navTo="/" />}</>}
        rightAdornment={
          <>
            {/* Desktop nav buttons */}
            {mdUp && <NavDesktop data={navConfig} />}

            {/* Login button */}
            <LoginButton sx={{ mx: 0 }} />
          </>
        }
      />

      {/* Main */}
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: mdUp ? `${HEADER.H_DESKTOP}px` : `${HEADER.H_MOBILE}px`,
        }}
      >
        {children}
      </Box>

      <Footer />
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
