import React, { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  INACTIVITY_WARNING_TIMEOUT as warningTimeout,
  INACTIVITY_LOGOUT_TIMEOUT as logoutTimeout,
} from 'src/config-global';

const InactivityLogout = () => {
  const { logout } = useAuthContext();

  const [showWarning, setShowWarning] = useState(false);

  // Checks for inactivity and logs out
  const checkForInactivity = async () => {
    // Get expire time from local storage
    const expiryTime = localStorage.getItem('expiryTime');

    // console.log(`Time left: ${expiryTime - Date.now()}`);
    if (expiryTime - Date.now() < warningTimeout * 1000) setShowWarning(true);

    // If expire time is earlier than now, log out
    if (expiryTime < Date.now()) {
      // console.log('Logging out');
      await logout();
    }
  };

  const updateExpireTime = () => {
    if (!showWarning) {
      // console.log('Resetting time!');

      // Set expire time to 10 seconds from now
      const expiryTime = Date.now() + logoutTimeout * 1000;

      // Set expire time in local storage
      localStorage.setItem('expiryTime', expiryTime);
    }
  };

  // Clean up old expiry time
  useEffect(() => {
    localStorage.removeItem('expiryTime');
  }, []);

  // Set interval to check for inactivity
  useEffect(() => {
    // Check for inactivity every second
    const interval = setInterval(() => {
      checkForInactivity();
    }, 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Update expire time on any user activity
  useEffect(() => {
    // Set initial expire time
    updateExpireTime();

    // Set event listeners
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    // Clean up
    return () => {
      // console.log('Cleanup');
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, [showWarning]);

  return (
    <>
      <Dialog open={showWarning}>
        <DialogTitle>{`Session Timeout Warning`}</DialogTitle>

        <DialogContent sx={{ color: 'text.secondary' }}>
          You will be logged out in {warningTimeout} seconds due to inactivity. Click below to continue your session.
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setShowWarning(false)}>
            Continue Session
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InactivityLogout;
