// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 85,
  H_DESKTOP: 85,
};

export const OFFSET = {
  H_MOBILE_OFFSET: 5,
  H_DESKTOP_OFFSET: 32,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
