// @mui
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
//
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

export default function Header({ leftAdornment, centerAdornment, rightAdornment }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <>
      {leftAdornment && (
        <Stack
          // flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={{ xs: 0.5, vs: 1.5 }}
          flex={1}
          // sx={{
          //   marginRight: { xs: 1, sm: 2 },
          // }}
        >
          {leftAdornment}
        </Stack>
      )}
      {centerAdornment && (
        <Stack
          // flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 0.5, vs: 1.5 }}
          flex={1}
          // sx={{
          //   marginRight: { xs: 1, sm: 2 },
          //   marginLeft: { xs: 1, sm: 2 },
          // }}
        >
          {centerAdornment}
        </Stack>
      )}
      {rightAdornment && (
        <Stack
          // flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, vs: 1.5 }}
          flex={1}
          // sx={{
          //   marginLeft: { xs: 1, sm: 2 },
          // }}
        >
          {rightAdornment}
        </Stack>
      )}
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        ...(mdUp && {
          width: 1,
          bgcolor: 'background.default',
          height: HEADER.H_DESKTOP,
          borderBottom: `dashed 1px ${theme.palette.divider}`,
        }),
      }}
    >
      <Toolbar
        sx={{
          maxWidth: 'lg',
          width: 1,
          mx: 'auto',
          height: 1,
          justifyContent: 'space-between',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  leftAdornment: PropTypes.node,
  centerAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
};
