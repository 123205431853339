// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
//
import { HEADER } from '../config-layout';

import Logo from 'src/components/logo';
//
import { Header, AccountPopover, FavoritesButton, SearchButton, NotificationsPopover } from '../_common';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Header
        leftAdornment={
          <>
            <Logo navTo="/dashboard" />
          </>
        }
        rightAdornment={
          <>
            {/* Toolbar settings button */}
            {/* <SearchButton /> */}

            {/* Favorites button */}
            <FavoritesButton />

            {/* Toolbar notifications button */}
            <NotificationsPopover />

            {/* Account button */}
            <AccountPopover title="0" tooltip="0 Matched Careers" />
          </>
        }
      />

      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: mdUp ? `${HEADER.H_DESKTOP}px` : `${HEADER.H_MOBILE}px`,
        }}
      >
        {children}
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
