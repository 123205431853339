import axios from 'src/utils/axios';

export const makeRequest = async (options) => {
  try {
    const response = await axios(options.config);
    const { data } = response;
    return { data, error: null };
  } catch (error) {
    const msg = error.message || error.statusText || 'http request failed';
    return { data: null, error: msg };
  }
};
