import { makeRequest } from './external';
import { API_ENDPOINTS } from 'src/utils/axios';

export const updateOrCreateProfile = async (accessToken, profileData) => {
  const config = {
    url: API_ENDPOINTS.user.profile,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: profileData,
  };

  const { data, error } = await makeRequest({ config });
  return { data: data || null, error };
};

// - TODO: After intro is submitted, add user email to the profileData then retrieve name and email from db for the items below instead of relying on the auth0 user object
// - TODO: Show name and email in account popover after profile setup
// - TODO: Add a nice mini card on homescreen saying welcome

// - UPDATE INTRODUCTION DATA ENDPOINT TO CREATE PROFILE IF NOT EXIST OR UPDATE IF DOES AND RENAME IT AND THE DB SCHEMA

export const getUserProfile = async (accessToken, id) => {
  const config = {
    url: `${API_ENDPOINTS.user.profile}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'user-id': id,
    },
  };

  const { data, error } = await makeRequest({ config });
  return { data: data || null, error };
};
