import PropTypes from 'prop-types';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
// config
import { AUTH0_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// TODO: Create an interface where users can sign into LinkedIn, Indeed, etc. when submitting an application or employers can quickly use my interface to send an email request or a request that's readable within great pursuits to send their data over so they can see a report containing personality type and other data
// TODO: 1. Business can create account 2. Request a users data via email 3. user signs in to great putsuits and grants access to specific data and can manage which companies they shared their data with
// TODO: Create workflow integration where companies can have this request automatically sent when a user applied for the job. Research how companies are currently setting up their job postings (workday, linkedin, etc.)

function AuthProviderWrapper({ children }) {
  const { isAuthenticated, user, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  // TODO: Remove
  const [popupClick] = useState(true);

  // User data process
  // 1. Intialize user data state as {}
  // (See: auth-guard.js)
  const [userDataFromAuth0, setUserDataFromAuth0] = useState({});

  // TODO: Remove if not using
  const handleLogout = useCallback(
    async (options) => {
      logout?.(options);
    },
    [logout]
  );

  useEffect(
    () => {
      // TODO: Check this object after user logout
      // User data process
      // 2. When isAuthenticated changes, set user id from auth0)
      // (See: auth-guard.js)
      if (isAuthenticated) setUserDataFromAuth0({ ...userDataFromAuth0, id: user?.sub, email: user?.email });
      else setUserDataFromAuth0({});
    },
    // Only user?.sub is needed as a dependency as changes to
    // the id will cover situations where the user object changes
    // ---
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, user?.sub, userDataFromAuth0?.id]
  );

  // GET ACCESS TOKEN
  const getAccessToken = useCallback(async () => {
    let token;
    try {
      token = getAccessTokenSilently?.();
    } catch (err) {
      await loginWithRedirect?.();
      token = getAccessTokenSilently?.();
    } finally {
      return token;
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  const handleLoginWithRedirect = useCallback(async () => {
    try {
      await loginWithRedirect?.({
        appState: {
          returnTo: window.location.href,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [loginWithRedirect]);

  const onUpdateUser = useCallback((data) => {
    setUserDataFromAuth0(data);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user: userDataFromAuth0,
      method: 'auth0', // TODO: REMOVE METHOD
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      onUpdateUser,
      getAccessTokenSilently: getAccessToken,
      handleLoginWithRedirect: handleLoginWithRedirect,
      logout: handleLogout,
    }),
    [status, userDataFromAuth0, handleLogout, onUpdateUser, getAccessToken, handleLoginWithRedirect]
  );

  // // TODO: Remove if not using
  // useEffect(() => {
  //   console.debug('Global user state:');
  //   console.debug(memoizedValue?.user);
  // }, [memoizedValue?.user]);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProviderWrapper.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }) => {
  const domain = AUTH0_API.domain ?? '';
  const clientId = AUTH0_API.clientId ?? '';
  const redirectUri = AUTH0_API.callbackUrl ?? '';
  const audience = AUTH0_API.audience ?? '';

  const onRedirectCallback = useCallback((appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      // TODO: Investigate this cacheLocation attribute
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
