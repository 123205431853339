import PropTypes from 'prop-types';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = ({ disabledLink = false, sx, navTo }) => {
  const logo = (
    <Box component="img" src="/logo/logo_single.svg" sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }} />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href={navTo} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
