import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
//
import { useAuthContext } from '../hooks';
import { useSnackbar } from 'src/components/snackbar';
import InactivityLogout from '../inactivity-logout';
// service
import { getUserProfile } from 'src/service/profile';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const { authenticated, handleLoginWithRedirect, user, onUpdateUser, getAccessTokenSilently } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const [userDataFromDB, setUserDataFromDB] = useState(null);

  const handleError = useCallback(
    (error) => {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  // User data process
  // 4. Now that userDataFromDB has been updated, update the original user object from useAuthContext
  // (See: auth-provider.js)
  useEffect(() => {
    onUpdateUser({ ...userDataFromDB });
  }, [userDataFromDB, onUpdateUser]);

  // User data process
  // 3. User data at this point should just be an empty object -> {}
  // When authenticated, get user data from DB and set it to userDataFromDB
  // (NOT the original user object from useAuthContext)
  // (See: auth-provider.js)
  useEffect(
    () => {
      async function getUserDataFromDB() {
        const userId = user?.id;

        if (!isEmpty(userId) && isEmpty(userDataFromDB?.id)) {
          try {
            if (!authenticated) handleLoginWithRedirect();
            const accessToken = await getAccessTokenSilently();
            const { data: profile, error } = await getUserProfile(accessToken, userId);
            if (error) handleError(error);
            else setUserDataFromDB({ ...user, ...profile });
          } catch (error) {
            handleError(error);
          }
        }
      }
      getUserDataFromDB();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticated, user?.id, userDataFromDB?.id, getAccessTokenSilently, handleError, handleLoginWithRedirect]
  );

  const check = useCallback(() => {
    if (!authenticated) {
      handleLoginWithRedirect();
    } else {
      setChecked(true);
    }
  }, [authenticated, handleLoginWithRedirect]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return (
    <>
      {children}
      <InactivityLogout />
    </>
  );
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
